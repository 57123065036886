import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ isDarkMode, isEnglish, toggleTheme, toggleLanguage }) => {
  const navigate = useNavigate();

  return (
    <nav className={`navbar ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="navbar-left">
        <img 
          src="/lionLogo.png" 
          alt="Home" 
          className="home-logo"
          onClick={() => navigate('/')}
        />
      </div>
      <div className="navbar-right">
        <div className="button-container">
          <button 
            className="theme-toggle" 
            onClick={toggleTheme}
            aria-label={isDarkMode ? "Cambiar a modo claro" : "Cambiar a modo oscuro"}
          >
            {isDarkMode ? '☀️' : '🌙'}
          </button>
          <button 
            className="language-toggle" 
            onClick={toggleLanguage}
            aria-label={isEnglish ? "Cambiar a español" : "Change to English"}
          >
            {isEnglish ? '🇪🇸' : '🇺🇸'}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
