import React from 'react';
import deleteAccountVideo from '../../img/deleteAccount.mp4';
import Navbar from '../Navbar/Navbar';
import './DeleteAccount.css';

const DeleteAccount = ({ isDarkMode, isEnglish, toggleTheme, toggleLanguage }) => {

  const texts = {
    title: isEnglish ? "How to Delete your Account" : "Cómo Eliminar tu Cuenta",
    description: isEnglish
      ? "Here are the steps to delete your MakeMatch account:"
      : "A continuación te mostramos los pasos para eliminar tu cuenta de MakeMatch:",
    steps: {
      title: isEnglish ? "Steps to follow:" : "Pasos a seguir:",
      list: isEnglish ? [
        "Access your profile in the app",
        "Select 'Settings' option",
        "Scroll down to 'Delete account'",
        "Confirm deletion"
      ] : [
        "Ingresa a tu perfil en la aplicación",
        "Selecciona la opción 'Configuración'",
        "Desplázate hasta 'Eliminar cuenta'",
        "Confirma la eliminación"
      ]
    },
    warning: isEnglish
      ? "⚠️ Remember that this action is irreversible and all your data will be permanently deleted."
      : "⚠️ Recuerda que esta acción es irreversible y todos tus datos serán eliminados permanentemente.",
    videoError: isEnglish
      ? "Your browser does not support the video element."
      : "Tu navegador no soporta el elemento de video.",
    buttonText: isEnglish
      ? "How to Delete your Account"
      : "Como eliminar tu Cuenta"
  };


  return (
    <div className={`delete-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <Navbar
        isDarkMode={isDarkMode}
        isEnglish={isEnglish}
        toggleTheme={toggleTheme}
        toggleLanguage={toggleLanguage}
      />

      <div className="delete-content">
        <h1 className="delete-title">{texts.title}</h1>
        <p className="delete-description">{texts.description}</p>
        <div className="video-container">
          <video
            controls
            className="tutorial-video"
          >
            <source src={deleteAccountVideo} type="video/mp4" />
            {texts.videoError}
          </video>
        </div>
        <div className="steps-container">
          <h2>{texts.steps.title}</h2>
          <ol>
            {texts.steps.list.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ol>
          <p className="warning-text">
            {texts.warning}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
