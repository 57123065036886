import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import './MainPage.css';

const MainPage = ({ imageUrl, speed, isDarkMode, isEnglish, toggleTheme, toggleLanguage }) => {
  const navigate = useNavigate();

  const texts = {
    terms: isEnglish ? "Terms and Conditions" : "Términos y Condiciones",
    deleteAccount: isEnglish ? "Delete Account" : "Eliminar Cuenta"
  };

  return (
    <div className={`main-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <Navbar 
        isDarkMode={isDarkMode} 
        isEnglish={isEnglish} 
        toggleTheme={toggleTheme} 
        toggleLanguage={toggleLanguage}
      />
      <div className="main-content">
        <div className="rotating-image-container" style={{ animationDuration: `${speed}s` }}>
          <img src={imageUrl} alt="Rotating" className="rotating-image" />
        </div>
        <div className="buttons-container">
          <button
            className="terms-button"
            onClick={() => navigate('/terms')}
          >
            {texts.terms}
          </button>
          <button
            className="delete-button"
            onClick={() => navigate('/delete-account')}
          >
            {texts.deleteAccount}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
