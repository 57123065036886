import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './components/MainPage/MainPage';
import ballImage from './img/balon.png';
import TermsOfService from './components/TermsOfService/TermsOfService';
import DeleteAccount from './components/DeleteAccount/DeleteAccount';
import { useState } from 'react';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isEnglish, setIsEnglish] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleLanguage = () => {
    setIsEnglish(!isEnglish);
  };

  return (
    <Router>
      <div className={`App ${isDarkMode ? 'dark-mode' : ''}`}>
        <Routes>
          <Route path="/" element={
            <header className="App-header">
              {/*  <CardPayment /> */}
              <MainPage 
                imageUrl={ballImage} 
                speed={24} 
                isDarkMode={isDarkMode} 
                isEnglish={isEnglish}
                toggleTheme={toggleTheme} 
                toggleLanguage={toggleLanguage}
              />{/* El balón girará con una velocidad de 24 segundos por vuelta */}
            </header>
          } />
          <Route path="/terms" element={
            <TermsOfService 
              isDarkMode={isDarkMode} 
              isEnglish={isEnglish}
              toggleTheme={toggleTheme} 
              toggleLanguage={toggleLanguage}
            />
          } />
          <Route path="/delete-account" element={
            <DeleteAccount 
              isDarkMode={isDarkMode} 
              isEnglish={isEnglish}
              toggleTheme={toggleTheme} 
              toggleLanguage={toggleLanguage}
            />
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
