import React from 'react';
import Navbar from '../Navbar/Navbar';
import './TermsOfService.css';

const TermsOfService = ({ isDarkMode, isEnglish, toggleTheme, toggleLanguage }) => {
  const texts = {
    title: isEnglish ? "MakeMatch Terms of Use" : "Términos de Uso de MakeMatch",
    lastUpdate: isEnglish ? "Last update: 29/01/2025" : "Última actualización: 29/01/2025",
    intro: isEnglish 
      ? "Welcome to MakeMatch. By downloading and using our application, you agree to comply with the following terms and conditions."
      : "Bienvenido a MakeMatch. Al descargar y usar nuestra aplicación, aceptas cumplir con los siguientes términos y condiciones.",
    sections: [
      {
        title: isEnglish ? "1. Service Description" : "1. Descripción del Servicio",
        content: isEnglish 
          ? "MakeMatch is an application that allows you to organize soccer matches, connect players, balance teams, and vote on participants' performance. Currently, the application is completely free and does not offer in-app purchases."
          : "MakeMatch es una aplicación que permite organizar partidos de fútbol, conectar jugadores, equilibrar equipos y votar sobre el rendimiento de los participantes. Actualmente, la aplicación es completamente gratuita y no ofrece compras dentro de la app."
      },
      {
        title: isEnglish ? "2. Usage Requirements" : "2. Requisitos de Uso",
        intro: isEnglish ? "To use MakeMatch, you must:" : "Para utilizar MakeMatch, debes:",
        list: isEnglish ? [
          "Be at least 13 years old or have consent from a parent or legal guardian.",
          "Comply with all applicable laws and regulations when using the application.",
          "Not use MakeMatch for fraudulent, deceptive, or illegal activities."
        ] : [
          "Tener al menos 13 años de edad o contar con el consentimiento de un padre o tutor legal.",
          "Cumplir con todas las leyes y regulaciones aplicables al utilizar la aplicación.",
          "No utilizar MakeMatch para actividades fraudulentas, engañosas o ilegales."
        ]
      },
      {
        title: isEnglish ? "3. Account Creation and Use" : "3. Creación y Uso de Cuenta",
        list: isEnglish ? [
          "You are responsible for maintaining the confidentiality of your account and password.",
          "You must not share your account with third parties.",
          "We reserve the right to suspend or delete accounts that violate these terms."
        ] : [
          "Eres responsable de mantener la confidencialidad de tu cuenta y contraseña.",
          "No debes compartir tu cuenta con terceros.",
          "Nos reservamos el derecho de suspender o eliminar cuentas que infrinjan estos términos."
        ]
      },
      {
        title: isEnglish ? "4. User Generated Content" : "4. Contenido Generado por los Usuarios",
        list: isEnglish ? [
          "You are responsible for the content you post within the application.",
          "We do not allow offensive, violent, discriminatory content or content that infringes third-party rights.",
          "MakeMatch reserves the right to remove inappropriate content or content that violates these terms."
        ] : [
          "Eres responsable del contenido que publiques dentro de la aplicación.",
          "No permitimos contenido ofensivo, violento, discriminatorio o que infrinja derechos de terceros.",
          "MakeMatch se reserva el derecho de eliminar contenido inapropiado o que viole estos términos."
        ]
      },
      {
        title: isEnglish ? "5. Limitation of Liability" : "5. Limitación de Responsabilidad",
        intro: isEnglish ? "MakeMatch is not responsible for:" : "MakeMatch no se hace responsable por:",
        list: isEnglish ? [
          "The accuracy of information provided by users.",
          "Problems or disputes between players organized through the app.",
          "Damages or losses derived from the use of the application."
        ] : [
          "La precisión de la información proporcionada por los usuarios.",
          "Problemas o disputas entre jugadores organizados a través de la app.",
          "Daños o pérdidas derivadas del uso de la aplicación."
        ]
      },
      {
        title: isEnglish ? "6. Service Modifications and Termination" : "6. Modificaciones y Terminación del Servicio",
        content: isEnglish 
          ? "We reserve the right to modify, suspend, or discontinue MakeMatch at any time without prior notice."
          : "Nos reservamos el derecho de modificar, suspender o interrumpir MakeMatch en cualquier momento sin previo aviso."
      },
      {
        title: isEnglish ? "7. Contact" : "7. Contacto",
        content: isEnglish 
          ? "If you have questions or inquiries about these terms, you can contact us at:"
          : "Si tienes dudas o consultas sobre estos términos, puedes contactarnos en:"
      }
    ]
  };

  return (
    <div className={`terms-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <Navbar 
        isDarkMode={isDarkMode} 
        isEnglish={isEnglish}
        toggleTheme={toggleTheme} 
        toggleLanguage={toggleLanguage}
      />
      <div className={`terms-content ${isDarkMode ? 'dark-mode' : ''}`}>
        <h1 className="terms-title">{texts.title}</h1>
        <p className="terms-date">{texts.lastUpdate}</p>

        <div className="terms-intro">
          <p>{texts.intro}</p>
        </div>

        {texts.sections.map((section, index) => (
          <section key={index} className="terms-section">
            <h2>{section.title}</h2>
            {section.content && <p>{section.content}</p>}
            {section.intro && <p>{section.intro}</p>}
            {section.list && (
              <ul>
                {section.list.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            )}
            {index === 6 && (
              <p>
                {section.content}{' '}
                <a href="mailto:soportemakematch@gmail.com" className="email-link">
                  soportemakematch@gmail.com
                </a>
              </p>
            )}
          </section>
        ))}
      </div>
    </div>
  );
};

export default TermsOfService;
